








import {Component, Prop, Vue} from 'vue-property-decorator';
import CBudgHeadReq from '@/modules/budget-request/budget-header.vue';


@Component({
    components: {
        'c-budg-head': CBudgHeadReq
    }
})
export default class CBudgHeadTest extends Vue {
    @Prop({
        required: false,
        default: null
    })
    private defaultProps!: any;

    @Prop({
        required: false,
        default: null
    })
    private form!: any;

    private test(numberForm: any) {
        this.$emit('testSpecific', numberForm);
        // console.log(numberForm);
    }

    created() {
        this.specificProp.sort((a: any, b: any) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
        });
    }


    private specificProp = [
        { name: '212-Выплаты вознаграждений по займам, полученным из вышестоящего бюджета местными исполнительными органами', spf: '212' },
        { name: '213-Выплаты вознаграждений по внутренним займам местных исполнительных органов', spf: '213' },
        { name: '331-Субвенции', spf: '331' },
        { name: '339-Текущие трансферты другим уровням государственного управления', spf: '339' },
        { name: '511-Бюджетные кредиты местным исполнительным органам, за исключением бюджетных кредитов на реализацию бюджетных инвестиционных проектов', spf: '511' },
        { name: '513-Бюджетные кредиты специализированным организациям', spf: '513' },
        { name: '612-Формирование и увеличение уставных капиталов субъектов квазигосударственного сектора', spf: '612' },
        { name: '711-Погашение основного долга перед вышестоящим бюджетом', spf: '711' },
        { name: '712-Погашение основного долга по государственным эмиссионным ценным бумагам, размещенным на внутреннем рынке', spf: '712' },
        { name: '154-Оплата услуг по исследованиям', spf: '154' },
        { name: '156-Оплата консалтинговых услуг', spf: '156' },
        { name: '157-Оплата услуг на проведение форумов, семинаров, конференций', spf: '157' },
        { name: '419-Приобретение прочих основных средств', spf: '419' },
        { name: '418-Материально-техническое оснащение государственных предприятий', spf: '418' },
        { name: '322-Трансферты физическим лицам', spf: '322' },
        { name: '412-Приобретение помещений, зданий, сооружений, передаточных устройств', spf: '412' },
        { name: '163-Затраты Фонда всеобщего обязательного среднего образования', spf: '163' },
        { name: '133-Возмещение средней заработной платы депутатам маслихата по их основному месту работы', spf: '133' }
    ];

    // private form = { 'name_ru': 'Форма 212', code: '212' };

    private filter = [
        { funcGr: 12,
            abp: [{
                gr: 12,
                pgr: 1,
                abp: 268,
                'name_ru': 'Управление пассажирского транспорта и автомобильных дорог области',
                'full_code': '26800',
                type: 3,
                'name_kk': 'Облыстың жолаушылар көлігі және автомобиль жолдары басқармасы',
                'budget_level_id': 2,
                progr: [{ 'id': 38914, 'gr': 12, 'pgr': 1, 'abp': 268, 'prg': 25, 'ppr': null, 'type': 4, 'name_ru': 'Капитальный и средний ремонт автомобильных дорог областного значения и улиц населенных пунктов', 'name_kk': 'Облыстық автомобиль жолдарын және елді-мекендердің көшелерін күрделі және орташа жөндеу', 'short_name_ru': null, 'short_name_kk': null, 'beg_date': null, 'end_date': null, 'full_code': '268250', 'develop_type': 0, 'transfer': null, 'is_sequest': null, 'budget_level_id': null, 'name': '25 - Капитальный и средний ремонт автомобильных дорог областного значения и улиц населенных пунктов' },
                    { 'id': 38919, 'gr': 12, 'pgr': 1, 'abp': 268, 'prg': 28, 'ppr': null, 'type': 4, 'name_ru': 'Реализация приоритетных проектов транспортной инфраструктуры', 'name_kk': 'Көлiк инфрақұрылымының басым жобаларын іске асыру', 'short_name_ru': null, 'short_name_kk': null, 'beg_date': null, 'end_date': null, 'full_code': '268280', 'develop_type': 0, 'transfer': null, 'is_sequest': null, 'budget_level_id': null, 'name': '28 - Реализация приоритетных проектов транспортной инфраструктуры' }
                ]
            }
            ] }
    ];
    private chgData(data: any) {
        console.log('data', data);
    }
}
